import React, {useEffect, useState} from 'react'
import {hotjar} from 'react-hotjar'

import {Currency} from '@posh/utils'
import {AuthedAccount} from 'apis/Auth/useLoginWithEmail'
import {GraphInterval, GraphType} from 'apis/Groups'
import {useFetchGroupGraph} from 'apis/Groups/useFetchGroupGraph'
import {GetGroupOutput} from 'apis/Groups/useGetGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import LineGraph from 'components/charts/LineGraph'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import moment from 'moment'
import {useNavigateToCreateEvent} from 'pages/CreateEvent/useNavigateToCreateEvent'

import GMVBar from '../GMVBar'

interface GroupAnalyticsViewProps {
  group?: GetGroupOutput
  groupId?: string
  currentUser?: AuthedAccount
  isFetchingGroup: boolean
  baseUrl: string
  search: string
  groupHasPaidEvents: boolean
}

const GroupAnalyticsView = (props: GroupAnalyticsViewProps) => {
  const {group, groupId, currentUser, groupHasPaidEvents} = props
  const [graphType, setGraphType] = useState<GraphType>('revenue')
  const [graphInterval, setGraphInterval] = useState<GraphInterval>('week')
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()

  const {data: groupGraph, isFetching: groupGraphIsFetching} = useFetchGroupGraph({
    groupId: groupId ?? '',
    graphInterval,
    hostId: currentUser?._id,
    trackingLink: currentUser?.trackingLink,
  })
  const [graphLabels, setGraphLabels] = useState<string[]>()
  const [graphData, setGraphData] = useState<number[]>()

  useEffect(() => {
    hotjar.initialize({
      id: 2193612,
      sv: 6,
    })
    hotjar.identify(groupId ?? '', {userProperty: groupId})
  }, [groupId])

  useEffect(() => {
    if (groupGraph?.graphData) {
      setGraphLabels(groupGraph.graphData.labels)
      if (groupGraph.graphData.revenue.reduce((partialSum, a) => partialSum + a, 0) === 0) {
        setGraphType('tickets')
        setGraphData(groupGraph.graphData.tickets)
      } else {
        setGraphType('revenue')
        setGraphData(groupGraph.graphData.revenue)
      }
    }
  }, [groupGraph])

  useEffect(() => {
    if (graphType === 'tickets') {
      setGraphData(groupGraph?.graphData.tickets)
    } else {
      setGraphData(groupGraph?.graphData.revenue)
    }
  }, [graphType])

  const graphRevenueTitle = () => {
    if (graphInterval === 'week') return 'Revenue This Week'
    else if (graphInterval === 'month') return 'Revenue This Month'
    else return 'Total Revenue'
  }

  const graphTicketsTitle = () => {
    if (graphInterval === 'week') return 'Tickets This Week'
    else if (graphInterval === 'month') return 'Tickets This Month'
    else return 'Total Tickets'
  }

  const emptyGraphLabels = () => {
    const time: any = new Date()
    const times = [time]
    for (let i = 1; i < 8; i++) {
      times.unshift(time - i * 1000 * 60 * 60 * 24)
    }
    return times.map(t => moment(t).format('MMM DD'))
  }

  const emptyGraphData = [0, 0, 0, 0, 0, 0, 0]

  const shouldShowEmptyGraphMessage = () => {
    if (
      (groupGraph?.totalTickets === 0 && graphType === 'tickets') ||
      (groupGraph?.totalRevenue === 0 && graphType === 'revenue')
    )
      return true
    else if (!graphLabels || !graphData) return true
    return false
  }

  const emptyGraphMessage =
    graphType === 'tickets'
      ? 'There were no tickets sold in this timeframe.'
      : 'There was no revenue generated in this timeframe.'

  return (
    <>
      <div className='WideGraphSection-TopBar'>
        <h2>
          <PoshImage src={group?.aviLocation} className='WideGraphSection-GroupAvi' />
          {group?.name}
          {group?.verified && <VerifiedGroupBadge size={15} organizerView />}
        </h2>
        <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_group_gmv_bar', ['group'])]}>
          {<GMVBar gmv={group?.gmv} />}
        </RequirePermissions>

        <div className='flex flex-wrap items-center justify-between gap-6'>
          <div className='WideGraphSection-Controls'>
            {groupHasPaidEvents && (
              <div
                onClick={() => {
                  trackMixpanelEvent('Header Analytics Revenue Chart Click-  Group Page')
                  setGraphType('revenue')
                }}
                className={
                  graphType === 'revenue'
                    ? 'WideGraphSection-Controls-GraphTab Active'
                    : 'WideGraphSection-Controls-GraphTab'
                }>
                <div className='WideGraphSection-Controls-GraphTab-Title'>{graphRevenueTitle()}</div>
                {groupGraph && (
                  <div className='WideGraphSection-Controls-GraphTab-Stat'>
                    {Currency.format(groupGraph?.totalRevenue, group?.currency ?? 'USD')}
                  </div>
                )}
              </div>
            )}
            <div
              onClick={() => {
                trackMixpanelEvent('Header Analytics Tickets Chart Click-  Group Page')
                setGraphType('tickets')
              }}
              className={
                graphType === 'tickets'
                  ? 'WideGraphSection-Controls-GraphTab Active'
                  : 'WideGraphSection-Controls-GraphTab'
              }>
              <div className='WideGraphSection-Controls-GraphTab-Title'>{graphTicketsTitle()}</div>
              <div className='WideGraphSection-Controls-GraphTab-Stat'>{groupGraph?.totalTickets.toLocaleString()}</div>
            </div>
          </div>

          <div className='WideGraphSection-IntervalPicker'>
            <div
              onClick={() => {
                setGraphInterval('week')
                trackMixpanelEvent('Header Analytics time frame Change Week-  Group Page')
              }}
              className={
                graphInterval === 'week'
                  ? 'WideGraphSection-IntervalPicker-Option Active'
                  : 'WideGraphSection-IntervalPicker-Option'
              }>
              1W
            </div>
            <div
              onClick={() => {
                setGraphInterval('month')
                trackMixpanelEvent('Header Analytics time frame Change Month-  Group Page')
              }}
              className={
                graphInterval === 'month'
                  ? 'WideGraphSection-IntervalPicker-Option Active'
                  : 'WideGraphSection-IntervalPicker-Option'
              }>
              1M
            </div>
            <div
              onClick={() => {
                setGraphInterval('all')
                trackMixpanelEvent('Header Analytics time frame Change All Time-  Group Page')
              }}
              className={
                graphInterval === 'all'
                  ? 'WideGraphSection-IntervalPicker-Option Active'
                  : 'WideGraphSection-IntervalPicker-Option'
              }>
              ALL
            </div>
          </div>
        </div>
      </div>
      <div style={{width: '100%'}}>
        {!groupGraphIsFetching ? (
          <>
            {shouldShowEmptyGraphMessage() ? (
              <div className='WideGraphSection-Empty text-center'>
                <i>{emptyGraphMessage}</i>
                <RequirePermissions
                  requiredPermissions={[
                    {
                      permissionKey: 'create_event',
                      applicableScopes: ['group'],
                    },
                  ]}>
                  <Button
                    className='dark'
                    onClick={() => {
                      navigateToCreateEvent({groupId})
                    }}>
                    + Create New Event
                  </Button>
                </RequirePermissions>
              </div>
            ) : (
              <LineGraph
                canvasStyle={{maxWidth: '100%'}}
                labels={graphLabels ? graphLabels : emptyGraphLabels()}
                data={graphData ? graphData : emptyGraphData}
                secondaryColor={graphType === 'tickets'}
                tooltipValueFormatter={(value, label = '') => {
                  if (graphType === 'revenue') {
                    return `${label}${Currency.format(value, group?.currency ?? 'USD')}`
                  }

                  return undefined
                }}
                yAxisLabelFormatter={value => {
                  if (graphType === 'revenue') {
                    return Currency.format(parseFloat(value), group?.currency ?? 'USD')
                  }
                  return value
                }}
                responsive={true}
                displayPrompt={false}
              />
            )}
          </>
        ) : (
          <div className='WideGraphSection-loader'>
            <SpinLoader />
          </div>
        )}
      </div>
    </>
  )
}

export default GroupAnalyticsView
