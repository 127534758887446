import React from 'react'

import BallOfTruth from 'components/BallOfTruth'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import moment from 'moment-timezone'

import {EventsTableListItemProps} from './types'

import '../index.scss'

export function EventsTableListItemContent(props: EventsTableListItemProps) {
  const {item, status, groupId, search} = props
  const {domain} = useGroupMatchParams()
  const eventId = item._id as string
  return (
    <div className='EventTable-row-identifiers'>
      <a
        className='EventTable-row-identifiers-header'
        href={`/${domain}/groups/${groupId}/events/${eventId}/overview${search}`}>
        <h4>{item.name}</h4>
      </a>
      <div className='EventTable-row-identifiers-info'>
        {moment.tz(item.startUtc, item.timezone).format('MMMM Do YYYY, h:mm a')}
      </div>
      <div
        className={`EventPageSectionHeader-details--status ${
          status === 'draft' ? 'orange' : status === 'live' ? 'green' : ''
        }`}>
        <BallOfTruth isLive={status === 'live'} isDraft={status === 'draft'} />{' '}
        {status[0].toUpperCase() + status.substring(1)}
      </div>
    </div>
  )
}
