import React, {forwardRef, useEffect, useState} from 'react'

import {ExperimentKey} from '@posh/model-types'
import {CurrencyCode} from '@posh/types'
import {CartItem} from 'apis/Carts'
import {EventTable, TicketEventPage} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {deleteIcon} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import useCartContext from 'domains/Cart/CartContext'
import {usePreviewCartBreakdown} from 'hooks/api/checkout/usePreviewCartBreakdown'
import {useExperiment} from 'hooks/useExperiment'
import {useTrackRudderStackEvent} from 'hooks/useTrackRudderstackEvent'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'
import {excludedEventIds} from 'utils/experiments'

import './styles.scss'

interface ItemRowProps {
  item: CartItem
  info: TicketEventPage | EventTable
  currency?: CurrencyCode
  showEventFees?: boolean
}

interface CartOverlayProps {
  isLightMode: boolean
  cartItems: CartItem[]
  unlockedTickets: TicketEventPage[]
  accentColor: string
  handleCheckoutRedirect: () => Promise<void>
  currency?: CurrencyCode
  showEventFees?: boolean
  eventTables?: EventTable[]
  eventId: string
  groupId: string
  onCheckout?: ({
    total,
    itemsWithInfo,
    currency,
  }: {
    total: number
    itemsWithInfo: Array<{item: CartItem; info: TicketEventPage | EventTable}>
    currency: CurrencyCode
  }) => void
  onChangeIsCartBreakdownExpanded?: (toggle: boolean) => void
  numberOfItems: number
}

const CartIcon = ({accentColor}: {accentColor: string}) => {
  return (
    <svg viewBox='0 0 512.003 512.003' xmlns='http://www.w3.org/2000/svg' fill={accentColor}>
      <g>
        <circle cx='211' cy='421.002' r='45' />
        <circle cx='391' cy='421.002' r='45' />
        <path d='m15 76.002h64.604c.835 3.007 76.745 276.318 71.938 259.014 1.802 6.489 7.72 10.986 14.458 10.986h270c6.694 0 12.583-4.438 14.429-10.884l61-210c1.289-4.526.381-9.39-2.446-13.154-2.842-3.75-7.28-5.962-11.982-5.962h-377.935l-13.608-49.014c-1.802-6.489-7.72-10.986-14.458-10.986h-76c-8.291 0-15 6.709-15 15s6.709 15 15 15zm361 210h-150c-8.291 0-15-6.709-15-15s6.709-15 15-15h150c8.291 0 15 6.709 15 15s-6.709 15-15 15zm-180-90h210c8.291 0 15 6.709 15 15s-6.709 15-15 15h-210c-8.291 0-15-6.709-15-15s6.709-15 15-15z' />
      </g>
    </svg>
  )
}

const ItemRow = ({item, info, currency, showEventFees}: ItemRowProps) => {
  const {tables, removeTable} = useCartContext()
  if (!info || item.quantity == 0) return null

  const isTicket = item.resourceType === 'ticket'
  const name = isTicket ? info.name : 'Table - ' + info.name
  const quantity = isTicket ? 'x' + item.quantity : ''
  const icon = isTicket ? `https://images.posh.vip/b2/tickets+(2).svg` : 'https://images.posh.vip/b2/table+(2).svg'

  return (
    <div className='CheckoutList-RowWrapper'>
      <div className='CheckoutList-TicketTier'>
        <PoshImage src={icon} />
        <div className='CheckoutList-TicketTier-Info'>
          <div>{name}</div>
          <div>
            {quantity}&nbsp;&nbsp;&nbsp;&nbsp;{getCurrencySymbol(currency)}
            {((showEventFees && 'totalPrice' in info ? info.totalPrice : info.price) * item.quantity).toFixed(2)}
          </div>
        </div>
      </div>
      {!isTicket && (
        <div onClick={() => removeTable(item.resourceId)} className='CheckoutList-RemoveTable'>
          <PoshImage src={deleteIcon} />
        </div>
      )}
    </div>
  )
}

const CartOverlay = forwardRef<HTMLDivElement, CartOverlayProps>((props, ref) => {
  const {
    isLightMode,
    cartItems,
    unlockedTickets,
    accentColor,
    handleCheckoutRedirect,
    currency,
    showEventFees,
    eventTables,
    eventId,
    groupId,
    onChangeIsCartBreakdownExpanded,
    numberOfItems,
  } = props
  const [isBreakdownShowing, setIsBreakdownShowing] = useState(true)
  useEffect(() => {
    onChangeIsCartBreakdownExpanded && onChangeIsCartBreakdownExpanded(isBreakdownShowing)
  }, [isBreakdownShowing, onChangeIsCartBreakdownExpanded])

  type ItemInfo = {
    item: CartItem
    info: TicketEventPage | EventTable
  }
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const itemsWithInfo: ItemInfo[] = []

  cartItems.forEach(i => {
    const info = unlockedTickets.find(t => t.id === i.resourceId)
    if (info) itemsWithInfo.push({item: i, info: info})
    if (eventTables) {
      const tableInfo = eventTables.find(t => t._id === i.resourceId)
      if (tableInfo) itemsWithInfo.push({item: i, info: tableInfo})
    }
  })

  const {data: previewData, isLoading} = usePreviewCartBreakdown({
    eventId,
    groupId,
    items: cartItems,
    metadata: {
      created: {
        originatingFrom: 'event_page',
      },
    },
  })

  const {trackCartPreviewCreated} = useTrackRudderStackEvent()
  useEffect(() => {
    if (previewData) {
      trackCartPreviewCreated(eventId, {experiment: groupedFeesExperiment})
    }
  }, [previewData, eventId, trackCartPreviewCreated])

  const hasFlatFee = (previewData?.cartInfo.feeBreakdown?.filter(item => item.type === 'flat').length ?? 0) > 0
  const groupedFeesExperiment = useExperiment({
    experimentKey: ExperimentKey.GROUPED_FEES_EXPERIMENT,
    enabled:
      (previewData?.cartInfo.total ?? 0) > 0 && !hasFlatFee && !showEventFees && !excludedEventIds.includes(eventId),
  })

  const total =
    (groupedFeesExperiment.value || showEventFees ? previewData?.cartInfo.total : previewData?.cartInfo.subtotal) ?? 0

  return (
    <>
      {isBreakdownShowing ? (
        <div className={`CheckoutList ${isLightMode ? 'lightmode' : ''}`} ref={ref}>
          <div className='CheckoutList-ticketButtonWrapper'>
            <PoshImage
              className='CheckoutList-ticketButtonWrapper-collapse'
              src='https://images.posh.vip/b2/down.svg'
              onClick={() => {
                setIsBreakdownShowing(false)
              }}
            />
            <div className='CheckoutList-Tickets'>
              {cartItems &&
                itemsWithInfo.map(item => (
                  <ItemRow
                    item={item.item}
                    info={item.info}
                    key={item.info.name}
                    currency={currency}
                    showEventFees={groupedFeesExperiment.value || showEventFees}
                  />
                ))}
            </div>
            <Button
              className={`CheckoutList-button ${isLightMode ? 'lightmode' : ''}`}
              style={{background: accentColor}}
              disabled={isLoading}
              onClick={() => {
                handleCheckoutRedirect()
                if (props.onCheckout)
                  props.onCheckout({
                    total,
                    itemsWithInfo,
                    currency: currency || 'USD',
                  })
              }}>
              <div className='CheckoutList-button-shimmer' />
              {isLoading ? (
                <SpinLoader height={20} color={'black'} />
              ) : (
                <span>
                  Checkout - {getCurrencySymbol(currency)}
                  {total.toFixed(2)}
                  {groupedFeesExperiment.value && <small className='ml-1'>(incl. fees)</small>}
                </span>
              )}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className={`CartButtonFilter ${isLightMode ? 'lightmode' : ''}`}>
            <div
              className={`CartButton ${isLightMode ? 'lightmode' : ''}`}
              onClick={() => {
                trackMixpanelEvent('Checkout Cart Icon Clicked-  Checkout Page')
                setIsBreakdownShowing(true)
              }}
              style={{border: `1px solid ${accentColor}`}}>
              <CartIcon accentColor={accentColor} />
              <div className={`CartCount ${isLightMode ? 'lightmode' : ''}`} style={{background: accentColor}}>
                {numberOfItems}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
})

CartOverlay.displayName = 'CartOverlay'

export default CartOverlay
