import {captureException} from '@sentry/react'
import {useStatsigClient} from '@statsig/react-bindings'
import {getRudderstackAnalytics} from 'helpers/RudderstackAnalytics'

type TrackPaidOrderCreatedParams = {
  userId?: string
  cartId?: string
  eventId?: string
}

type TrackAddToCartParams = {
  count: number
}

type TrackCartCreatedParams = {
  eventId: string
}

type OrderSummaryToggledParams = {
  cartId: string
}

export const useTrackRudderStackEvent = () => {
  const rudderStackClient = getRudderstackAnalytics()
  const {client: statsigClient} = useStatsigClient()
  const {stableID: StatsigStableID} = statsigClient.getContext()

  // Wrapper function to automatically add statsigCustomIDs to all events
  const trackEvent = async (eventName: string, value: any, params: Record<string, any> = {}) => {
    try {
      rudderStackClient?.track(eventName, {
        value,
        statsigCustomIDs: ['stableID', StatsigStableID],
        ...params,
      })
    } catch (err) {
      captureException(err)
    }
  }

  const trackUserMarketingOptIn = async (params: {location: string; optInPopUpExperiment?: boolean}) => {
    await trackEvent('user_marketing_opt_in_web', params.location, params)
  }

  const trackPaidOrderCreated = async (totalPrice: number, params: TrackPaidOrderCreatedParams) => {
    await trackEvent('paid_order_created', totalPrice, params)
  }

  const trackAddToCart = async (ticketId: string, params: TrackAddToCartParams) => {
    await trackEvent('add_to_cart', ticketId, params)
  }

  const trackCartPreviewCreated = async (eventId: string) => {
    await trackEvent('cart_preview_created', eventId)
  }

  const trackCartCreated = async (cartId: string, params: TrackCartCreatedParams) => {
    await trackEvent('cart_created', cartId, params)
  }

  const orderSummaryToggled = async (isOpen: boolean, params: OrderSummaryToggledParams) => {
    await trackEvent('order_summary_toggled', isOpen, params)
  }

  return {
    trackUserMarketingOptIn,
    trackPaidOrderCreated,
    trackAddToCart,
    trackCartPreviewCreated,
    trackCartCreated,
    orderSummaryToggled,
  }
}
