import React from 'react'

import {SpinLoader} from 'components/Loaders/SpinLoader'
import {trpc} from 'lib/trpc'
import {WalletCards} from 'lucide-react'

import './styles.scss'

interface AddToWalletProps {
  orderId: string
  accentColor?: string
  mixpanelCallback?: () => void
}
export const AddToWallet = (props: AddToWalletProps) => {
  const orderId = props.orderId
  const accentColor = props.accentColor
  const {mutateAsync: getAppleWalletPass, isLoading} = trpc.accounts.getAppleWalletPass.useMutation()

  const downloadPass = async () => {
    props.mixpanelCallback && props.mixpanelCallback()
    const url = await getAppleWalletPass({orderId})
    if (!url) return
    window.location.assign(url)
  }

  return (
    <>
      <>
        {isLoading ? (
          <div style={{marginTop: '10px !important'}} className='AppleWalletButton-loader'>
            <SpinLoader height={30} />
          </div>
        ) : (
          <div style={{border: `1px solid ${accentColor ?? '#fff'}`}} onClick={downloadPass}>
            <WalletCards height={24} width={24} color={accentColor ?? '#fff'} />
          </div>
        )}
      </>
    </>
  )
}
