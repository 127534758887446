import {StatsigExperimentKey} from '@posh/model-types'
import {ApiObject} from '@rudderstack/analytics-js'
import {captureException} from '@sentry/react'
import {useStatsigClient} from '@statsig/react-bindings'
import {getRudderstackAnalytics} from 'helpers/RudderstackAnalytics'

type UseExperimentParams = {
  experimentKey: StatsigExperimentKey
  ignoreOverrides?: boolean
  enabled?: boolean
  disableTracking?: boolean
}

/**
 * A hook that returns the value of an experiment from statsig.
 * If the experiment is disabled, it returns a value of false.
 * If the experiment is enabled, it returns the value of the experiment.
 * If the experiment is not found, it returns a value of false.
 *
 * Tracking:
 * * In order to have the data at a raw data level,
 * we have to add the experiment details in the metadata of Rudderstack events.
 * This will allow us to:
 * - Correlate user behavior/events with experiment variations.
 * - Join everything downstream in your warehouse or BI tools using those identifiers.
 */
export const useExperiment = ({experimentKey, enabled = true, disableTracking = false}: UseExperimentParams) => {
  const statsigClient = useStatsigClient()

  // If the experiment check is not enabled, then just return false (which never calls the statsig api yet)
  if (!enabled) {
    return {value: false}
  }

  const {name, value, ruleID} = statsigClient.getExperiment(experimentKey)

  if (!disableTracking) {
    const rudderStackClient = getRudderstackAnalytics()
    try {
      rudderStackClient.track('Experiment Viewed', {
        experiment_name: name,
        experiment_id: ruleID,
        variation: value,
      } as ApiObject)
    } catch (err) {
      captureException(err)
    }
  }

  return {value: value.enabled as boolean}
}
