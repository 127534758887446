import React, {useEffect, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {searchIconUrl} from 'assets/images/s3images'
import {PoshImage} from 'components/PoshImage/PoshImage'

interface AttendeesSearchBarProps {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}
export const AttendeesSearchBar = (props: AttendeesSearchBarProps) => {
  const {setSearchQuery} = props
  const [input, setInput] = useState('')
  const queryClient = useQueryClient()

  const clearQueries = () => {
    queryClient.invalidateQueries(['groupAttendeesPaged'])
    queryClient.invalidateQueries(['eventAttendees'])
  }

  useEffect(() => {
    if (!input) return
    const timeout = setTimeout(() => {
      setSearchQuery(input)
      clearQueries()
    }, 500)
    return () => clearTimeout(timeout)
  }, [input])

  return (
    <div className='Attendees-searchBar'>
      <input
        type='text'
        name='searchbar'
        placeholder='Search (Name, Email, Phone Number)'
        value={input}
        onChange={e => {
          setInput(e.target.value)
        }}
      />
      <PoshImage src={searchIconUrl} />
    </div>
  )
}
