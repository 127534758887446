import {FeatureFlag} from '@posh/model-types'
import {TGroupSmsOptIn, TPoshSmsOptIn} from '@posh/sms-opt-ins'
import {GetAccountOptInsInput, useGetAccountOptIns, useUpdateAccountOptIn, useUpdateGroupOptIn} from 'apis/Optins'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {useFeatureGate} from 'hooks/useFeatureGate'

/**
 * The possible values for the smsOptIn field in the OptIn document
 */
export enum SmsOptInOptions {
  Yes = 'yes',
  NoApplication = 'no-application',
  NoSubaccount = 'no-subaccount',
}

/**
 * Hook to get/control the account and group opt ins
 */
export function useAccountGroupOptIns({
  groupId,
  location,
  optInPopUpExperiment,
  toastSuccess = 'none',
}: {
  groupId?: string
  location?: string
  optInPopUpExperiment?: boolean
  toastSuccess?: 'group' | 'account' | 'none' | 'all'
}) {
  const {userId} = useSessionContext()
  const {showToast} = useToast()

  /**
   * Group SMS Opt Ins
   */
  const groupQuery = {groupID: groupId, fields: ['optIns', 'group', 'account']}
  const isSmsOptInsEnabled = useFeatureGate(FeatureFlag.SMS_OPT_INS)
  const {
    data: orgOptInsResponse,
    refetch: refetchOrgOptIns,
    isLoading: isLoadingOrgOptIns,
    isError: isErrorOrgOptIns,
  } = useGetAccountOptIns(groupQuery, {enabled: !!userId && !!groupId})
  const orgOptInData = orgOptInsResponse?.data[0] as TGroupSmsOptIn | undefined
  const isOptedOutToGroupSms = orgOptInData?.optIns?.smsOptIn === SmsOptInOptions.NoApplication
  const {
    mutate: updateGroupOptIn,
    mutateAsync: updateGroupOptInAsync,
    isLoading: isUpdatingGroupOptIn,
  } = useUpdateGroupOptIn({
    query: groupQuery,
    onSuccess: () => {
      ;(toastSuccess === 'group' || toastSuccess === 'all') &&
        showToast({
          title: 'Notifications enabled',
          subtitle: `You will now receive notifications for this event and any future events from ${orgOptInData?.group.name}`,
          type: 'success',
        })
    },
  })

  /**
   * Posh SMS Opt Ins
   */
  const accountQuery = {optInType: 'posh-sms', fields: ['optIns']} as GetAccountOptInsInput
  const {
    data: accountOptInsResponse,
    refetch: refetchAccountOptIns,
    isLoading: isLoadingAccountOptIns,
    isError: isErrorAccountOptIns,
  } = useGetAccountOptIns(accountQuery)
  const accountOptInData = accountOptInsResponse?.data[0] as TPoshSmsOptIn | undefined
  const isOptedInToPoshSms = accountOptInData?.optIns?.marketing?.smsOptIn === SmsOptInOptions.Yes
  const isOptedOutToPoshSms = accountOptInData?.optIns?.marketing?.smsOptIn === SmsOptInOptions.NoApplication
  const isPoshSmsBlocked = accountOptInData?.optIns?.marketing?.smsOptIn === SmsOptInOptions.NoSubaccount
  const {mutate: updateAccountOptIns} = useUpdateAccountOptIn({
    location,
    optInPopUpExperiment,
    query: accountQuery,
    onSuccess: () => {
      ;(toastSuccess === 'account' || toastSuccess === 'all') &&
        showToast({
          title: 'Notifications enabled',
          type: 'success',
        })
    },
  })

  // We display the opt in checkbox if the user is opted out of group sms or posh sms
  const shouldDisplayOptInForm =
    !isLoadingAccountOptIns &&
    !isErrorAccountOptIns &&
    (isOptedOutToPoshSms || isOptedOutToGroupSms) && // if the user is opted out of posh sms or group sms, we display the opt in checkbox
    isSmsOptInsEnabled

  const refetchOptIns = () => {
    refetchOrgOptIns()
    refetchAccountOptIns()
  }
  return {
    shouldDisplayOptInForm,
    orgOptInData,
    accountOptInData,
    isOptedInToPoshSms,
    isPoshSmsBlocked,
    updateGroupOptIn,
    updateGroupOptInAsync,
    updateAccountOptIns,
    refetchOptIns,
    isUpdatingGroupOptIn,
    isLoadingOrgOptIns,
    isLoadingAccountOptIns,
    isErrorAccountOptIns,
    isErrorOrgOptIns,
  }
}
