import React, {InputHTMLAttributes} from 'react'
import {ReactGoogleAutocompleteProps, usePlacesWidget} from 'react-google-autocomplete'

import {LongLat} from 'apis/Events/types'
interface LocationData {
  address: string
  country?: string
  location: LongLat
}

const GOOGLE_MAPS_PUBLIC_API_KEY = 'AIzaSyC8Rk4-dB09rn_XA6dnNkmgZfmK-qkfOrs'
// mask to only these fields to avoid unnecessary data/charges
// https://developers.google.com/maps/documentation/javascript/place-data-fields
const DEFAULT_GOOGLE_LOCATION_FIELDS = [
  'name',
  'address_components',
  'formatted_address',
  'geometry',
  'type',
  'name',
  'utc_offset_minutes',
]
const DEFAULT_GOOGLE_LOCATION_TYPES = ['geocode', 'establishment']

interface PoshLocationInputProps {
  label?: string
  className?: string
  inputClassName?: string
  placeholder?: string
  address?: string
  onPlaceSelected: (data: LocationData) => void
  setCountry?: boolean
  options?: string[]
}

export function loadGoogleMapsScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (window.google) {
      resolve()
      return
    }

    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_PUBLIC_API_KEY}&libraries=places`
    script.async = true
    script.defer = true
    script.onload = () => resolve()
    script.onerror = () => reject(new Error('Failed to load Google Maps script'))
    document.head.appendChild(script)
  })
}

export type UseLocationInputRefParams = Omit<ReactGoogleAutocompleteProps, 'apiKey'>
/**
 * Wrapper for the `usePlacesWidget` hook that provides a ref to the input element.
 * @param params Options for the `usePlacesWidget` hook.
 * @returns A ref to the input element.
 */
function useLocationInputRef(params: UseLocationInputRefParams) {
  return usePlacesWidget<HTMLInputElement>({
    apiKey: GOOGLE_MAPS_PUBLIC_API_KEY,
    ...params,
    options: {
      ...(params?.options ?? {}),
      fields: params?.options?.fields ?? DEFAULT_GOOGLE_LOCATION_FIELDS,
      types: params?.options?.types ?? DEFAULT_GOOGLE_LOCATION_TYPES,
    },
  })
}

export type LocationInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'ref'> & {
  googlePlacesInputOptions: UseLocationInputRefParams
}

/**
 * An unstyled, generalized input component that uses the Google Places API to provide location suggestions.
 * @param props The input's props, including the Google Places API options.
 */
export function LocationInput(props: LocationInputProps) {
  const {googlePlacesInputOptions, ...inputProps} = props
  const {ref} = useLocationInputRef(googlePlacesInputOptions)

  return <input ref={ref} {...inputProps} />
}

const PoshLocationInput = (props: PoshLocationInputProps) => {
  const {address, onPlaceSelected, placeholder, label, className, inputClassName, options, setCountry} = props

  const locationInputOptions: UseLocationInputRefParams = {
    onPlaceSelected: place => {
      const latitude = place.geometry?.location?.lat() ?? 0
      const longitude = place.geometry?.location?.lng() ?? 0
      const country = place.address_components?.find(c => c.types.includes('country'))?.short_name ?? 'US'
      onPlaceSelected({
        address: place.formatted_address ?? '',
        country: setCountry ? country : undefined,
        location: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      })
    },
    options: {
      types: options ?? DEFAULT_GOOGLE_LOCATION_TYPES,
    },
  }

  return (
    <div className={'inputLabelWrapper' + ' ' + className}>
      {label && <label className='m-0'>{label}</label>}
      <LocationInput
        placeholder={placeholder}
        className={'poshInput' + ' ' + inputClassName}
        defaultValue={address}
        googlePlacesInputOptions={locationInputOptions}
      />
    </div>
  )
}

export default PoshLocationInput
