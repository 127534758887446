import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {RoleKey} from '@posh/model-types'
import {useGetSectionalData} from 'apis/Analytics/useGetSectionalData'
import {useFetchGroupData} from 'apis/Groups/useFetchGroupData'
import {GetGroupEventsEvent, useFetchGroupEvents} from 'apis/Groups/useFetchGroupEvents'
import {useGetGroup} from 'apis/Groups/useGetGroup'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useCheckPermissions} from 'apis/Permissions/useHasPermissionsEnabled'
import AnalyticsGrid, {Statistic} from 'components/data/AnalyticsGrid'
import BlastsTable from 'components/data/BlastsTable'
import EventsTable from 'components/data/EventsTable'
import {EventRowAction} from 'components/data/EventsTable/ListItem/types'
import Button from 'components/form/Button'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import RequirePermissions from 'components/RequirePermissions'
import useSessionContext from 'domains/Auth/SessionContext'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {useGroupBaseUrl} from 'domains/Groups/helpers'
import usePermissionsContext from 'domains/Teams/PermissionsContext'
import moment from 'moment'
import {useNavigateToCreateEvent} from 'pages/CreateEvent/useNavigateToCreateEvent'
import {usePageParams} from 'pages/PoshAppLayout'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import {getGroupCtaBanner} from '../Finance/GroupCtaBanner'
import OrdersListView from '../Orders/OrdersListView'
import DeleteEventModal from './DeleteEventModal'
import DuplicateEventModal from './DuplicateEventsModal'
import GroupAnalyticsView from './GroupAnalyticsView'

import './styles.scss'

const Overview = () => {
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {groupId} = useGroupMatchParams()
  const {domain} = usePageParams()
  const {currentUser} = useSessionContext()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  const {accountRole} = usePermissionsContext()

  const {data: groupData} = useFetchGroupData(groupId ?? '')
  const {data, isFetching: groupEventsIsFetching} = useFetchGroupEvents(
    {groupId: groupId!, status: 'active'},
    {enabled: !!groupId},
  )
  const {events: upcomingEvents} = data ?? {}

  const {data: pastEventsData, isFetching: pastEventsIsFetching} = useFetchGroupEvents(
    {groupId: groupId!, status: 'past', limit: 1},
    {enabled: !!groupId},
  )
  //Event fetch limit is 1 so we can do this
  const lastEvent = pastEventsData?.events.at(0)
  // The past event shows up if it's within the last 7 days in the case that there are other upcoming events, otherwise show it regardless
  const {hasPermission: hasPermissionToViewPastEventAnalytics, isLoading: isLoadingPermissions} = useCheckPermissions(
    ['view_sales_analytics'],
    groupId!,
    lastEvent?._id ?? '',
  )
  const showPastEvent =
    lastEvent &&
    hasPermissionToViewPastEventAnalytics &&
    (moment().diff(moment(lastEvent.endUtc), 'days') <= 7 || upcomingEvents?.length === 0)
  const {data: pastEventAnalytics, isFetching: isFetchingPastEventAnalytics} = useGetSectionalData(
    {
      groupId: groupId!,
      eventId: lastEvent?._id ?? '',
      dataPoints: ['totalGmv', 'totalTicketsSold'],
    },
    {enabled: !!showPastEvent && !!lastEvent && !!groupId},
  )
  const isEventsFetching =
    groupEventsIsFetching || pastEventsIsFetching || isFetchingPastEventAnalytics || isLoadingPermissions
  const [totalRevenue, totalTickets] = [
    pastEventAnalytics?.find(stat => stat.type === 'totalGmv')?.mainValue?.value ?? 0,
    pastEventAnalytics?.find(stat => stat.type === 'totalTicketsSold')?.mainValue?.value ?? 0,
  ]

  const {data: getGroupData, isFetching: isFetchingGroup} = useGetGroup({groupId: groupId ?? ''})
  const {group} = getGroupData ?? {}

  const [isShowingDeleteEventModal, setIsShowingDeleteEventModal] = useState(false)
  const [actionEvent, setActionEvent] = useState<GetGroupEventsEvent | undefined>(undefined)
  const [isShowingDuplicateEventModal, setIsShowingDuplicateEventModal] = useState(false)

  const search = useLocation().search

  const currencySymbol = getCurrencySymbol(group?.currency) as string

  const length = groupData ? groupData.eventsLength : undefined
  const attendees = groupData ? groupData.totalAttendees : undefined
  const revenue = groupData ? groupData.totalRevenue : undefined

  const analyticsData: (Statistic | null)[] = [
    {
      name: 'Events',
      value: length,
    },
    {
      name: 'Total Attendees',
      value: attendees,
    },
    !!revenue
      ? {
          name: 'Total Revenue',
          value: revenue,
          isCurrency: true,
        }
      : null,
  ]

  const handleRowAction = (action: EventRowAction, eventId: string) => {
    if (action == EventRowAction.EDIT) {
      navigate(`/${domain}/groups/${groupId}/events/${eventId}/overview${search}`)
    }

    if (action == EventRowAction.DELETE) {
      setActionEvent(upcomingEvents!.find(event => event._id == eventId)!)
      setIsShowingDeleteEventModal(true)
    }

    if (action == EventRowAction.DUPLICATE) {
      setActionEvent(upcomingEvents!.find(event => event._id == eventId)!)
      setIsShowingDuplicateEventModal(true)
    }

    if (action == EventRowAction.VIEW) {
      const event = upcomingEvents!.find(event => event._id == eventId)!
      window.open(`/e/${event.url}` + search, '_blank')
    }
  }

  const baseUrl = useGroupBaseUrl()

  return (
    <>
      <PoshHelmet title={`Overview - ${group?.name}`} />
      {!!group &&
        getGroupCtaBanner({
          canInitiatePayouts: group.canInitiatePayouts,
          canAcceptPayments: group.canAcceptPayments,
          needsStripeOnboarding: group.needsStripeOnboarding,
          baseUrl,
          country: group.country,
          tapToPayState: group.tapToPayState,
          upcomingEvent: upcomingEvents?.at(0),
          revenue,
        })}
      <RequirePermissions
        requiredPermissions={[
          {
            permissionKey: 'view_sales_analytics',
            applicableScopes: ['group'],
          },
        ]}
        fallbackComponent={<div style={{height: '270px', width: '100%'}} />}>
        <div className='WideGraphSection'>
          <GroupAnalyticsView
            group={group}
            groupId={groupId}
            currentUser={currentUser}
            isFetchingGroup={isFetchingGroup}
            baseUrl={baseUrl}
            search={search}
            groupHasPaidEvents={!!revenue}
          />
        </div>
      </RequirePermissions>
      <div className='Overview'>
        <div className='Overview-top'>
          <RequirePermissions
            requiredPermissions={[
              {
                permissionKey: 'view_sales_analytics',
                applicableScopes: ['group'],
              },
            ]}>
            <AnalyticsGrid currencySymbol={currencySymbol} stats={analyticsData} GroupDash={true} gold={false} />
          </RequirePermissions>
          <RequirePermissions
            requiredPermissions={[
              {
                permissionKey: 'create_event',
                applicableScopes: ['group'],
              },
            ]}>
            <Button
              onClick={() => {
                trackMixpanelEvent('Create New Event -  Curator overview above recent orders')
                navigateToCreateEvent({groupId})
              }}>
              + Create New Event
            </Button>
          </RequirePermissions>
        </div>

        {((upcomingEvents && upcomingEvents.length > 0) || lastEvent) && (
          <>
            <h4>Events</h4>
            <div className='Overview-eventsTable'>
              {upcomingEvents && upcomingEvents.length > 0 && (
                <EventsTable
                  data={upcomingEvents ?? []}
                  onRowAction={handleRowAction}
                  isHost={(accountRole?.roleKey ?? ('host' as RoleKey)) === 'host'}
                  activeOnly={true}
                  isFetching={groupEventsIsFetching}
                />
              )}
              {lastEvent && (
                <Button className='darker' onClick={() => navigate(`${baseUrl}/overview/all-events` + search)}>
                  View Past Events
                </Button>
              )}
            </div>
          </>
        )}

        <RequirePermissions
          requiredPermissions={[
            {
              permissionKey: 'view_blasts',
              applicableScopes: ['group'],
            },
          ]}>
          <h4>Recent SMS Campaigns</h4>
          <BlastsTable limit={3} />
        </RequirePermissions>
        <RequirePermissions
          requiredPermissions={[
            {
              permissionKey: 'view_orders_list',
              applicableScopes: ['group'],
            },
          ]}>
          <h4>Orders</h4>
          <OrdersListView expanded={false} />
          <Button
            className='darker'
            onClick={() => {
              navigate(`/${domain}/groups/${groupId}/orders` + search)
            }}>
            View More
          </Button>
        </RequirePermissions>

        <DeleteEventModal
          event={actionEvent}
          isOpen={isShowingDeleteEventModal}
          onClose={() => setIsShowingDeleteEventModal(false)}
        />
        {actionEvent && isShowingDuplicateEventModal && (
          <DuplicateEventModal
            event={actionEvent}
            isOpen={isShowingDuplicateEventModal}
            onClose={() => setIsShowingDuplicateEventModal(false)}
            width={830}
          />
        )}
      </div>
    </>
  )
}

export default Overview
