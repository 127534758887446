/**
 * Event IDs to be excluded from Grouped Fees experiment
 * and to be cleaned up after the experiment concludes.
 * This was a "quick and dirty" solution due to organisers
 * complaining and not wanting to participate in the test.
 *
 * TODO: Clean up after GROUPED_FEES_EXPERIMENT concludes.
 */
const excludedEventIds = [
  '66ad508cae6ccd14d06fbf75',
  '67d36159018e5e816ffc96b8',
  '67d364d5018e5e816ffd64bd',
  '67650807e9e0e5128a6eeed1',
  '676509afe9e0e5128a6f1b8b',
  '67650a0fe9e0e5128a6f2051',
  '67650a57e9e0e5128a6f2274',
  '67650ac3e9e0e5128a6f2621',
  '67650b11e9e0e5128a6f3038',
  '67650b51e9e0e5128a6f321e',
  '6767334b0c15ea7782eda83c',
  '67bbc3f69457be323205a797',
  '67e86d09ccb6168e444357b0',
  '67eac6ad6e4b74241f029bb1',
  '67ee92acb9e5d524e87b3989',
  '67dcf294053e0307487f0d57',
  '67b7041a1e38578f5075e4a7',
  '67e19f5de1ff4247876d6641',
  '67ecf2df763f4ee676d0a9e6',
  '67d99df9b3a96c3130b077c4',
  '67dca391b58edfe6d28bb571',
  '67eafc7938d13c6c9548907c',
  '67eaa1d5f3e1ce8edb4a406b',
  '676daf244e0e3da79725690a',
  '67c9e284486430b0d84c45e9',
  '67eeb9ab70e4bc90f381eaa9',
  '67eeb9ab70e4bc90f381eaac',
  '67eeb9ab70e4bc90f381eaaf',
  '67eeb9ab70e4bc90f381eaa9',
  '67eeb9ab70e4bc90f381eaac',
  '67eeb9ab70e4bc90f381eaaf',
  '67d0e9c6a9ee42810f71afa3',
  '67e16f713ef20c30af19cd9e',
  '67e17f36159a0516887e1713',
  '67e181e8159a0516887e5168',
  '67eaf87abdd8641137a72b37',
  '67eb10cdc9c261b2cc93a380',
  '67ec1795fab68b4836f2d844',
  '67d4942f6513a3e02b39cdf2',
  '67dc908128ee93f904900b78',
  '67e341e5d3ccce9e1e8d8c8d',
  '67e36bdeefc28ffcdfc25331',
  '67e6e74b9c4f6748c1dd042b',
  '67edd9286e2730207757793f',
  '67e1f7cce43f5abe6e74dead',
  '67c783de67a2a7bc21e29b0c',
  '67d4a91d44396637095d6270',
  '67d4b15744396637095f8cf7',
  '67eac79c2fb3e37b08e66a26',
  '67eac8be2fb3e37b08e676a4',
  '67f159e55a6a5d1cffb502d9',
  '67f15b815a6a5d1cffb52596',
  '67622ad1f63746ef07e9d434',
  '67aa6cb3ab4f4febfa638f3c',
  '67eac29e7ed1bc80a1d8f03f',
  '67be31f42cd94bf86adacf86',
  '679fb37006f3657d4ca31cdd',
  '67be27936523654635d6e869',
  '67be28925238c29c7f895ade',
  '67be323b2cd94bf86adad986',
  '67be32f22cd94bf86adaef21',
  '67ce5d1f296b0476b05e28d5',
  '67ea0ba8bf776d803ca91a1f',
  '678ad49358c6643b8ac2bbc6',
]

export {excludedEventIds}
